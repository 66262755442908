/**
 * Googlemaps
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

//$googlemap-border         : 1px solid #cc0000 !default; // (1)
$googlemap-default-height : 500px !default;
//$googlemap-padding        : $padding-base-horizontal-xsmall !default; // (1)

$googlemap-bubble-font-family : Arial, Helvetica, Arial, sans-serif !default; // (1) (2)
$googlemap-bubble-font-size   : 12px !default; // (1) (2)