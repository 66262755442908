/**
 * Listen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/**
 * Liste zuruecksetzen.
 *
 * @param string  $type                Eine ungeordnete (ul) oder geordnete
 *                                     Liste (ol)
 * @param boolean $remove-list-margin  Listenaussenabstaende entfernen
 * @param boolean $remove-list-padding Listeninnenabstaende entfernen
 * @param boolean $remove-item-margin  Listeneintragaussenabstaende entfernen
 * @param boolean $remove-item-padding Listeneintraginnenabstaende entfernen
 */
@mixin list-reset($type: ul, $remove-list-margin: true, $remove-list-padding: true, $remove-item-margin: true, $remove-item-padding: true) {
	border     : 0 none;
	list-style : none;

	@if $remove-list-margin {
		margin : 0;
	}

	@if $remove-list-padding {
		padding : 0;
	}

	@if $type == dl {
		& dt,
		& dd {
			border : 0 none;

			@if $remove-item-margin {
				margin : 0;
			}

			@if $remove-item-padding {
				padding : 0;
			}
		}
	} @else {
		& li {
			border     : 0 none;
			list-style : none;

			@if $remove-item-margin {
				margin : 0;
			}

			@if $remove-item-padding {
				padding : 0;
			}
		}
	}
}