/**
 * Backgrounds.
 *
 * @author Bootstrap
 * @package namRED
 */

/**
 * Buttontyp.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
@mixin bg-variant($parent, $color) {
	#{$parent} { background-color : $color; }

	a#{$parent}:hover { background-color : darken($color, 10%); }
}