/**
 * Alerts
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$alert-border               : 1px solid transparent; // (1)(2)
$alert-border-radius        : $comp-border-radius-base; // (1)(2)
//$alert-font-size            : $font-size-small; // (1)(2)
$alert-font-weight          : $font-weight-bold; // (1)(2)
$alert-link-font-weight     : $font-weight-normal; // (1)(2)
$alert-link-text-decoration : underline; // (1)(2)
$alert-margin               : $margin-base-vertical-large 0;
$alert-padding              : ($comp-padding-vertical-large * 2) ($comp-margin-horizontal-large * 2);

$alert-primary-bg-color     : rgba($brand-primary, 0.25) !default;
$alert-primary-border-color : $brand-primary;
$alert-primary-color        : $brand-primary;
$alert-primary-color-link   : $brand-primary;

$alert-secondary-bg-color     : rgba($brand-secondary, 0.25) !default;
$alert-secondary-border-color : $brand-secondary;
$alert-secondary-color        : $brand-secondary;
$alert-secondary-color-link   : $brand-secondary;

$alert-tertiary-bg-color     : rgba($brand-tertiary, 0.25) !default;
$alert-tertiary-border-color : $brand-tertiary;
$alert-tertiary-color        : $brand-tertiary;
$alert-tertiary-color-link   : $brand-tertiary;

$alert-success-bg-color     : rgba($brand-success, 0.25) !default;
$alert-success-border-color : $brand-success;
$alert-success-color        : $brand-success;
$alert-success-color-link   : $brand-success;

$alert-info-bg-color     : rgba($brand-info, 0.25) !default;
$alert-info-border-color : $brand-info;
$alert-info-color        : $brand-info;
$alert-info-color-link   : $brand-info;

$alert-warning-bg-color     : rgba($brand-warning, 0.25) !default;
$alert-warning-border-color : $brand-warning;
$alert-warning-color        : $brand-warning;
$alert-warning-color-link   : $brand-warning;

$alert-danger-bg-color     : rgba($brand-danger, 0.25) !default;
$alert-danger-border-color : $brand-danger;
$alert-danger-color        : $brand-danger;
$alert-danger-color-link   : $brand-danger;