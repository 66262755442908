/**
 * Bilder
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$thumbnail-border-radius : 0 !default;

$thumbnail-caption-bg-color    : transparent !default;
//$thumbnail-caption-font-size   : 15px !default; // zum Anwenden einkommentieren
$thumbnail-caption-font-style  : $font-style-italic !default;
$thumbnail-caption-font-weight : $font-weight-light !default;
$thumbnail-caption-padding     : $comp-margin-vertical-large 0 !default; // top, right, bottom, left

$thumbnail-align-margin-horizontal : ($comp-margin-horizontal-base * 2) !default; // nur ein Wert
$thumbnail-align-margin-vertical   : ($comp-margin-vertical-base * 2) !default; // nur ein Wert

$thumbnail-size       : 100% !default;
$thumbnail-size-small : 18% !default;
$thumbnail-size-large : 45% !default;
$thumbnail-size-full  : 100% !default;

$thumbnail-zoom-bg-color       : $brand-primary !default;
//$thumbnail-zoom-bg-color-hover : transparent !default; // zum Anwenden einkommentieren
$thumbnail-zoom-color          : $brand-secondary !default;
//$thumbnail-zoom-color-hover    : $color-white !default; // zum Anwenden einkommentieren
//$thumbnail-zoom-font-size      : $font-size-large !default;
$thumbnail-zoom-icon-content   : "\f065" !default; // "\f00e" !default;
$thumbnail-zoom-size           : 40px !default;
