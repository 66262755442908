/**
 * Akkordeons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

//$accordion-border-color : $brand-tertiary !default;
//$accordion-border-style : solid !default;
//$accordion-border-width : 2px !default;

/*
 * Toggle
 */

$accordion-toggle-border-color : $brand-tertiary !default;
$accordion-toggle-border-style : solid !default;
$accordion-toggle-border-width : 2px !default;
$accordion-toggle-bg-color     : transparent !default;
$accordion-toggle-color        : $brand-tertiary !default;
$accordion-toggle-font-size    : $font-size-xlarge; // (1)(2)
$accordion-toggle-font-weight  : $font-weight-light; // (1)
$accordion-toggle-margin       : $comp-margin-horizontal-large 0 0;
$accordion-toggle-padding      : $comp-padding-horizontal-small !default;

$accordion-toggle-icon-height        : 30px !default;
$accordion-toggle-icon-border-radius : 0 !default;
$accordion-toggle-icon-bg-color      : transparent !default;
$accordion-toggle-icon-content       : '\f105' !default;
$accordion-toggle-icon-color         : $brand-tertiary !default;
$accordion-toggle-icon-font-size     : 1.25em;
$accordion-toggle-icon-align         : left !default; // left oder right

// Hover
$accordion-toggle-bg-color-hover     : transparent !default;
$accordion-toggle-border-color-hover : $brand-primary !default;
$accordion-toggle-color-hover        : $brand-primary;

$accordion-toggle-icon-bg-color-hover : transparent !default;
$accordion-toggle-icon-content-hover  : '\f105' !default;
$accordion-toggle-icon-color-hover    : $brand-primary !default;

// Active

$accordion-toggle-bg-color-active     : transparent !default;
$accordion-toggle-border-color-active : $brand-primary !default;
$accordion-toggle-color-active        : $brand-primary;

$accordion-toggle-icon-bg-color-active : transparent !default;
$accordion-toggle-icon-content-active  : '\f107' !default;
$accordion-toggle-icon-color-active    : $brand-primary !default;

/*
 * Panel
 */

$accordion-panel-border-color : transparent !default;
$accordion-panel-border-style : none !default;
$accordion-panel-border-width : 0 !default;
$-acc-pa-pa-helper            : ($comp-padding-horizontal-base * 2);
$accordion-panel-padding      : $-acc-pa-pa-helper $-acc-pa-pa-helper ($-acc-pa-pa-helper - $comp-margin-horizontal-large) !default;