/**
 * Tabellen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/**
 * Farbvarianten (Zeile, Zelle).
 * (Bootstrap adaptiert)
 *
 * @param string  $state
 * @param string  $bg-color     Hintergrundfarbe
 */
@mixin table-row-variant($state, $bg-color : transparent) {
	table,
	.table {
		> thead > tr,
		> tbody > tr,
		> tfoot > tr {
			> td.#{$state},
			> th.#{$state},
			&.#{$state} > td,
			&.#{$state} > th { background-color : $bg-color; }
		}
	}

	// Hover states for `.table-hover`
	// Note: this is not available for cells or rows within `thead` or `tfoot`.
	.table-hover > tbody > tr {
		> td.#{$state}:hover,
		> th.#{$state}:hover,
		&.#{$state}:hover > td,
		&:hover > .#{$state},
		&.#{$state}:hover > th {
			background-color : darken($bg-color, 5%);
		}
	}
}