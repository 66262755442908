
/* Force output of above line by adding a unicode character. ♫ */
/**
 * Druckausgabe.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * Konfigurationen, Erweiterungen etc..
 * -------------------------------------------------------------------------- */
/**
 * Basis
 *
 * Hauptkonfiguration mit zusaetzlichem Konfigurationsimport am Ende.
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* =============================================================================
 *
 * Schriftangaben
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Abstaende, Radius etc.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Breakpoints (Mediaqueries)
 *
 * -----------------------------------------------------------------------------
 * Desktop-First-Methode
 * ========================================================================== */
/* =============================================================================
 *
 * Cursor
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Farben
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Status
 * -------------------------------------------------------------------------- */
/* =============================================================================
 *
 * Konfigurationsimport
 *
 * -----------------------------------------------------------------------------
 * Zwecks Uebersichtlichkeit ausgegliedert/separiert.
 * ========================================================================== */
/**
 * Schrift
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* =============================================================================
 *
 * Body
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Ueberschriften
 *
 * ========================================================================== */
/* =============================================================================
 *
 *  Sonstiges
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Code
 * -------------------------------------------------------------------------- */
/**
 * Grundgeruest
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* =============================================================================
 *
 * Grundcontainer der Seite
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Seitenkopf
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Seitenfuss
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Showcase
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Breadcrumb
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Seite
 * -------------------------------------------------------------------------- */
/**
 * Navigation(en)
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* =============================================================================
 *
 * Hauptnavigation.
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Untermenue.
 * -------------------------------------------------------------------------- */
/* =============================================================================
 *
 * Servicenavigation.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Metanavigation.
 *
 * ========================================================================== */
/**
 * Komponenten
 *
 * Grundkonfiguration und Konfigurationsimport einzelner Komponenten.
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* =============================================================================
 *
 * Globaler Innenabstaende einzelnder Komponenten (Buttons, Input).
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Globale Rahmenangaben.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Globaler Borderadius einzelner Komponenten.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Konfigurationsimport einzelner komponenten.
 *
 * ========================================================================== */
/**
 * Links
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Formulare
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * Fieldset
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Label
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Eingabe-, Auswahlelemente
 * -------------------------------------------------------------------------- */
/*
 * Border radius
 */
/*
 * Events, Status
 */
/*
 * Input Group
 */
/* -----------------------------------------------------------------------------
 * Platzhalter
 * -------------------------------------------------------------------------- */
/**
 * Styled Checkboxes/Radiobuttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */
/**
 * Bilder
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Indikatoren (Ladeanzeige)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Rating stars
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Dropdowns
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Alerts
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Buttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * Standard
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Primaer
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Sekundaer
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Tertiaer
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Success
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Disabled
 * -------------------------------------------------------------------------- */
/**
 * Navigationen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* =============================================================================
 *
 * Global
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Breadcrumb
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Step Wizard
 *
 * ========================================================================== */
/**
 * Seitenblaettern (Pagination)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Tabs
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Tabellen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * @.table
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @.table-condensed
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @.table-bordered
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @.table-striped
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @Hover
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @Hintergrundfarben
 * -------------------------------------------------------------------------- */
/**
 * Listen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * Liste mit Links
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Liste mit Rahmen
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Tabellenliste
 * -------------------------------------------------------------------------- */
/**
 * Listengruppen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Panels
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Wells
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * 'Wells' scrollbar.
 * -------------------------------------------------------------------------- */
/**
 * Akkordeons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/*
 * Toggle
 */
/*
 * Panel
 */
/**
 * Modals
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Tooltipps
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Video, Audio
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Visitenkarten
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Galerien
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Googlemaps
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Stapel
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Artikels
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Import von Funktionen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Be- und Umrechnungen (PX, EM, REM, ...).
 *
 * @author Stephen Rushing, eSiteful
 * @url http://github.com/stephenr85/sass-unity
 * @package namRED
 */
/**
 * If a number has a .00001 decimal, it's probably a rounding issue and needs
 * to be dropped.
 * Example:
 *     sub-px(13.00005px) = 13px
 *
 * @function sub-px
 * @param string/number $dim
 *
 * return number
 */
/**
 * Removes the unit from a number.
 * Example:
 *     strip-unit(16px) = 16
 *
 * @function strip-unit
 * @param string/number $num
 *
 * return number
 */
/**
 * Find the first unit in a list of numbers.
 *
 * Examples:
 *     first-unit(0 12% 10% 1px) = px
 *
 * @function first-unit
 * @param number/list $dims
 *
 * return string
 */
/**
 * Convert any number to a px, or ensure a px value.
 * Percentages are retained. Use percent() to calculate static values.
 *
 * Examples:
 *     px(1rem) = 16px
 *     px(1 4rem 2em 10%) = 1px 64px 26px 10%
 *
 * @function px
 * @param number/list $dim
 *
 * return number A px value
 */
/**
 * Convert a number to an rem, or ensure an rem value.
 * Percentages are retained. Use percent() to calculate static values.
 *
 * @function rem
 * @param number $dim
 *
 * return number A rem value
 */
/**
 * Sometimes em values are still viable, obviously, but you have to know the
 * context for calculations. The baseline is stored, so each subsequent call
 * will use the previous $baseline parameter, if none is passed.
 *
 * @function em
 * @param number $dim
 * @param number $baseline [global $em-baseline] However many px = 1em,
 *                                               or the relative em of the
 *                                               current context.
 * @param boolean $adjust  [false]               When true, adjusts the global
 *                                               $em-baseline to whatever the
 *                                               result is. This is useful if
 *                                               you want to set things
 *                                               font-size: em(12px).
 *
 * return number A em value
 */
/**
 * Convert arbitrary units to or from percentages.
 * This works a little differently than the other unit functions, because it
 * does not retain units of its own type. In other words, a % value will be
 * converted, rather than returned entact.
 *
 * Examples:
 *     percent(24px, 240px) = 10%
 *     percent(1.5rem, 240px) = 10%
 *     percent(10%, 240px) = 24px
 *     percent(10%, rem(240px)) = 1.5rem
 *
 * @function percent
 * @param number $dim     If a %
 * @param number $context The total width for the calculation of the percentages.
 *
 * @return number If $dim is a % value, returns the percent calculation in the
 *                same unit as $context.
 *                If $dim is another unit, a % will be returned.
 */
/**
 * Visually relative to the pixels per inch of a device, but provides a
 * consistent mathmatical formula for converting units to pts for print.
 * Totally untested with actual print at this point.
 *
 * Examples:
 *     pt(16px) = 12pt
 *
 * @function pt
 * @param number $dim
 * @param number $px-per-inch Defaults to global $px-per-inch (96).
 * @param number $pt-per-inch Defaults to global $pt-per-inch (72).
 *
 * @return number A pt value.
 */
/**
 * Visually relative to the pixels per inch of a device, but provides a
 * consistent mathmatical formula for converting units to pts for print.
 * Totally untested with actual print at this point.
 *
 * Examples:
 *     cast-unit(px, 3em, 4em, (5))
 *
 * @function cast-unit
 * @param string $unit [em,pt,px,rem]
 * @param number $dims
 */
/**
 * Convert all numbers to the same unit type. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     unity(12px, 1em) = unity(12px, 1em)
 *     unity(1em, 14px, 1.2rem, (16, 8%)) = 1em .25em 1.45em 16em 8%
 *
 * @function unity
 * @param number $dims
 *
 * @return number
 */
/**
 * Add numbers, normalizing them first. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     plus(1rem, 4px, 7em, 5)
 *
 * @function plus
 * @param number $dims
 *
 * @return number
 */
/**
 * Subtract numbers, normalizing them first. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     minus(20rem, 4px, 7em, 5)
 *
 * @function minus
 * @param number $dims
 *
 * @return number
 */
/**
 * Use rem units with px fallback.
 * Specify a px, em, or rem value with each property. Unitless values will be
 * considered an rem.
 *
 * Examples:
 *     rem(font-size 12px, line-height 1.4em)
 *
 * @mixin rem
 * @param string $properties
 *
 * @return number
 */
/**
 * Einige Hilfsfunktionen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Zeilenhoehe kalkulieren.
 *
 * @param number $base
 * @param number $target
 *
 * return number
 */
/**
 * Kleinster gemeinsamer Teiler.
 *
 * @param number $a
 * @param number $b
 *
 * return number
 */
/**
 * Aspect-Ratio durch Angabe von Höhe und Breite bestimmen.
 *
 * @param number $x  Breite (Numerator 1)
 * @param number $y  Höhe  (Denominator 1)
 *
 * return number Prozent
 */
/**
 * Durch Angabe einer Originalbreite und -hoehe und einer Zielbreite oder -hoehe
 * wird per Berechnung des Seitenverhaeltnisses die fehlende Zielbreite- oder
 * -hoehe berechnet.
 *
 * @param number $x  Originalbreite (Numerator 1)
 * @param number $y  Originalhoehe  (Denominator 1)
 * @param number $x2 Zielbreite (Numerator 2)
 * @param number $y2 Zielhoehe (Denominator 2)
 *
 * return number Zielbreite oder -hoehe
 */
/**
 * Import von Vorlagen, wiederverwendbaren Mustern.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * Utilities
 * -------------------------------------------------------------------------- */
/**
 * Overflow-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Overflow setzen.
 *
 * Examples:
 *     .selector {
 *         @include overflow(x, scroll);
 *     }
 *
 * @param string  $direction 'x' oder 'y'
 * @param boolean $type      'hidden', 'scroll' etc..
 */
/**
 * Mediaqueries generieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Mediaqueries generieren.
 *
 * @param string  $name Schluessel in der $breakpopints-Map.
 * @param boolean $push Schluessel hinzufuegen.
 */
/**
 * Ueberschriften.
 *
 * @author Heiko Pfefferkorn (i-fabrik GmbH)
 * @package namRED
 */
/**
 *
 * This file is part of namRED Content Management Framework.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author heiko
 * @package namRED
 */
/* -----------------------------------------------------------------------------
 * Komponenten
 * -------------------------------------------------------------------------- */
/**
 * Alerts.
 *
 * @author Bootstrap (modifiziert: Heiko Pfefferkorn)
 * @package namRED
 */
/**
 * Alert variant.
 * (Bootstrap adaptiert)
 *
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe (wenn null dann autom. Bestimmung
 *                              per darken() anhand $bg-color)
 * @param string  $color        Textfarbe (wenn null dann autom. Bestimmung per
 *                              darken() anhand $bg-color)
 * @param string  $link-color   Textfarbe (wenn null dann autom. Bestimmung per
 *                              darken() anhand $bg-color)
 */
/**
 * Alerts.
 *
 * @author Bootstrap (modifiziert: Heiko Pfefferkorn)
 * @package namRED
 */
/**
 * Buttontyp.
 * (Bootstrap adaptiert)
 *
 * @param string  $color              Textfarbe
 * @param string  $bg-color            Hintergrundfarbe
 * @param string  $border-color       Rahmenfarbe
 * @param string  $color-hover        Textfarbe Mouseover etc. (Standard '$color').
 * @param string  $bg-color-hover      Hintergrundfarbe Mouseover. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()'.
 * @param string  $border-color-hover Rahmenfarbe Mouseover. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()'.
 */
/**
 * Buttongroesse.
 * (Bootstrap adaptiert)
 *
 * @param string  $padding-vertical
 * @param string  $padding-horizontal
 * @param string  $font-size
 * @param string  $line-height
 * @param string  $border-radius
 */
/**
 * Panels.
 *
 * @author Bootstrap (modifiziert: Heiko Pfefferkorn)
 * @package namRED
 */
/**
 * Panel variant.
 * (Bootstrap adaptiert)
 *
 * @param string  $border-color         Rahmenfarbe
 * @param string  $heading-color        Ueberschrift - Textfarbe
 * @param string  $heading-bg-color     Ueberschrift - Hintergrundfarbe
 * @param string  $heading-border-color Ueberschrift - Rahmenfarbe
 */
/**
 * Backgrounds.
 *
 * @author Bootstrap
 * @package namRED
 */
/**
 * Textfarbe plus :hover.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
/**
 * Textfarben.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
/**
 * Listen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Events einem Formularelement zuweisen.
 * (Bootstrap adaptiert)
 *
 * @param string  $event        'hover', 'active', 'focus'
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
/**
 * Mit Statusklassen versehene Formularelemente bezueglich ihrer Fabrgebung etc.
 * definieren.
 * (Bootstrap adaptiert)
 *
 * Beispiele:
 *     .form-control.info
 *     .form-control.valid
 *     .form-control.warning
 *
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
/*
 * Formularelemente-Validierungsstatus bezueglich ihrer Farbgebung etc.
 * definieren.
 * (Bootstrap adaptiert)
 *
 * Beispiele:
 *     .has-error [Formularelemnte]
 *     .has-success [Formularelemnte]
 *     .has-warning [Formularelemnte]
 *
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
/**
 * Tabellen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Farbvarianten (Zeile, Zelle).
 * (Bootstrap adaptiert)
 *
 * @param string  $state
 * @param string  $bg-color     Hintergrundfarbe
 */
/**
 * Pagination-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Pagination
 * (Bootstrap adaptiert)
 *
 * @param string  $padding-vertical   Vertikaler Innenabstand
 * @param string  $padding-horizontal Horizontaler Innenabstand
 * @param string  $font-size          Schriftgroesse
 * @param string  $border-radius      Border-Radius
 */
/* -----------------------------------------------------------------------------
 * Skins
 * -------------------------------------------------------------------------- */
/**
 * Backgrounds.
 *
 * @author Bootstrap
 * @package namRED
 */
/**
 * Buttontyp.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Horizontaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 */
/**
 * Vertikaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 */
/**
 * Diagonaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 * @param string $deg           Gradanzahl
 */
/* -----------------------------------------------------------------------------
 * Layout
 * -------------------------------------------------------------------------- */
/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Grid-Spalten generieren.
 *
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
/**
 * Grid-Variante generieren.
 *
 * @param string $ident        Teil des neuen Selectors der an '.row-' angefuegt
 *                             wird. Bsp. (md): .row-md
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
/**
 * Box-Model-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Box-Model setzen.
 *
 * @param string  $val
 */
/**
 * Clearfix-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Clearfix fuer moderne Browser
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 *
 * Source: http://nicolasgallagher.com/micro-clearfix-hack/
 */
/**
 * Ausrichtungen/Zentrierung von Elementen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Element zentrieren.
 *
 * @param string $width
 */
/**
 * Listen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Liste zuruecksetzen.
 *
 * @param string  $type                Eine ungeordnete (ul) oder geordnete
 *                                     Liste (ol)
 * @param boolean $remove-list-margin  Listenaussenabstaende entfernen
 * @param boolean $remove-list-padding Listeninnenabstaende entfernen
 * @param boolean $remove-item-margin  Listeneintragaussenabstaende entfernen
 * @param boolean $remove-item-padding Listeneintraginnenabstaende entfernen
 */
/* -----------------------------------------------------------------------------
 * Standarddefinitionen.
 * -------------------------------------------------------------------------- */
/**
 * Druckausgabe (Helfer).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
.page-max-width,
.main-breadcrumb .nav-breadcrumb,
.main > .section > *,
.main > .section-district-finder > .article > .article-header > *, .main-header > .main-header-wrap, .main-footer, .main-showcase > .component > .component-wrap {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  width: 100%; }

nav,
.nav, .hide-on-print,
.main-showcase,
.main-header > .main-header-wrap > .nav,
.main-header .trigger,
.main-footer .nav-service,
.cookie-bar,
.main > .section-find-district {
  display: none !important; }

.show-on-print {
  display: block !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-after: avoid; }

audio,
img,
video,
.figure,
.thumbnail,
.vcard {
  page-break-after: auto; }

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  page-break-before: avoid; }

audio,
img,
video,
.figure,
.thumbnail,
.vcard {
  page-break-before: auto; }

table,
button,
input,
select,
textarea,
blockquote,
pre,
code,
figure,
li,
hr,
ul,
ol,
a, audio,
img,
video,
.figure,
.thumbnail,
.vcard, h1,
h2,
h3,
h4,
h5,
h6, .article-box {
  page-break-inside: avoid; }

/**
 * Druckausgabe (Basis).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
* {
  -webkit-print-color-adjust: exact !important; }
  *, *::before, *::after {
    text-shadow: none !important;
    box-shadow: none !important; }

body,
html {
  height: auto;
  overflow: auto;
  padding: 0; }

body {
  text-rendering: optimizeLegibility; }

/* -----------------------------------------------------------------------------
 * @ Komponenten
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @ Typografie
 * -------------------------------------------------------------------------- */
h2,
h3,
h4,
h5,
h6,
p,
a {
  orphans: 3;
  widows: 3; }

abbr[title],
acronym[title] {
  border: 0;
  text-decoration: none; }
  abbr[title]::after,
  acronym[title]::after {
    content: " (" attr(title) ")"; }

/* -----------------------------------------------------------------------------
 * Projectspezifische Definitionen.
 * -------------------------------------------------------------------------- */
/**
 * Druckausgabe (projektspezifisch).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */
/**
 * Aus-, Einblenden von bestimmten Elementen.
 */
/* -----------------------------------------------------------------------------
 * Vendors
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Komponenten
 * -------------------------------------------------------------------------- */
/**
 * '../components/_links'
 */
a[href]:after {
  content: ""; }

/**
 * '../components/_accordions'
 */
.accordion-toggle {
  background-color: transparent;
  border-color: #967a50;
  color: #967a50; }
  .accordion-toggle::before {
    background-color: transparent;
    color: #967a50;
    content: "";
    line-height: 28px;
    padding-left: 0; }

.accordion-panel {
  display: block; }

/**
 * '../components/_articles'
 */
.article > .article-body > .article-body-section {
  margin-bottom: 30px;
  margin-top: 30px; }

.article-box.property-box > .article-box-wrap {
  padding-bottom: 30px; }

.article-box.property-box .controls {
  display: none; }

/**
 * @ '../components/_stacks'
 */
.stack-article-box {
  margin-top: 0; }
  .stack-article-box > .article-box {
    height: auto !important;
    margin-top: 0 !important; }
    .stack-article-box > .article-box:nth-child(2n+1) {
      clear: both; }

/**
 * @ '../components/_tables'
 */
thead {
  display: table-header-group; }

tr {
  page-break-inside: avoid; }

/**
 * @ '../components/_tooltip'
 */
.tooltip {
  display: none !important; }

/**
 * @ '../components/_googlemaps'
 */
.googlemap {
  height: 500px !important;
  padding-bottom: 0 !important;
  width: 100%; }
  .googlemap-map {
    height: 100%;
    bottom: auto !important;
    left: auto !important;
    position: relative !important;
    right: auto !important;
    top: auto !important; }
  .googlemap > img {
    width: 100%; }

/**
 * @ '../components/_vcards'
 */
.vcard .thumbnail > .image {
  background-image: none !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important; }
  .vcard .thumbnail > .image > img {
    bottom: auto;
    display: block;
    left: auto;
    position: relative;
    right: auto;
    top: auto; }

/* -----------------------------------------------------------------------------
 * Kernaufbau
 * -------------------------------------------------------------------------- */
/**
 * @ '../core/_scaffolding'
 */
.main > .section {
  padding-bottom: 30px !important;
  padding-top: 30px !important; }
  .main > .section:not(:first-child) {
    page-break-before: always; }

/**
 * @ '../core/_header'
 */
.main-header > .main-header-wrap {
  height: auto;
  padding-bottom: 30px; }

/**
 * @ '../core/_footer'
 */
.main-footer {
  margin-top: 0; }
  .main-footer-wrap {
    padding-bottom: 0; }
  .main-footer .copy {
    display: block;
    float: none;
    text-align: center;
    width: 100%; }

/**
 * @ '../core/_showcase'
 */
/**
 * @ '../core/_elements'
 */
