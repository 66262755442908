/**
 * Formulare
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$form-margin : 0; // ($margin-base-vertical-large * 2);

/* -----------------------------------------------------------------------------
 * Fieldset
 * -------------------------------------------------------------------------- */

$fieldset-bg-color : transparent !default;
$fieldset-margin   : $margin-base-vertical-large 0 !default;
$fieldset-padding  : 0 !default;

$legend-bg-color      : $color-gray-light !default;
$legend-border-bottom : 0 none !default;
$legend-color         : #abaaaa !default;
$legend-font-size     : $font-size-xlarge !default;
$legend-margin        : $margin-base-vertical 0 !default;
$legend-padding       : 0 !default;

/* -----------------------------------------------------------------------------
 * Label
 * -------------------------------------------------------------------------- */

$label-color       : $body-text-color !default;
$label-font-style  : $font-style-italic !default;
$label-font-weight : $font-weight-light !default;

/* -----------------------------------------------------------------------------
 * Eingabe-, Auswahlelemente
 * -------------------------------------------------------------------------- */

$input-bg-color     : $color-gray-lighter !default;
$input-border-color : $color-gray !default;
$input-box-shadow   : none !default;
$input-border-width : $comp-border-width !default;
$input-border-style : $comp-border-style !default;
$input-color        : $brand-tertiary !default;
$input-font-size    : $font-size-base !default;
$input-font-style   : $font-style-italic !default;
$input-font-weight  : $font-weight-light !default;
//$input-height       : ($line-height-computed + ($comp-padding-vertical-base * 2) + 2) !default;
$input-height       : ($line-height-computed + ($comp-padding-vertical-base * 2) + 2) !default;
$input-height-large : (ceil($font-size-large * $line-height-large) + ($comp-padding-vertical-large * 2) + 2) !default;
$input-height-small : (floor($font-size-small * $line-height-small) + ($comp-padding-vertical-small * 2) + 2) !default;

/*
 * Border radius
 */

$input-border-radius       : $comp-border-radius-base !default;
$input-border-radius-large : $comp-border-radius-large !default;
$input-border-radius-small : $comp-border-radius-small !default;

/*
 * Events, Status
 */

$input-bg-color-hover     : $input-bg-color !default;
$input-border-color-hover : darken($color-gray-light, 7%) !default;
$input-color-hover        : $input-color !default;

$input-bg-color-focus     : $input-bg-color !default;
$input-border-color-focus : $brand-primary !default; //darken($color-gray, 10%) !default;
$input-color-focus        : $brand-tertiary !default;

$input-bg-color-placeholder     : $input-bg-color !default;
$input-border-color-placeholder : $input-border-color !default;
$input-color-placeholder        : darken($color-gray, 15%) !default;

$input-bg-color-readonly     : $input-bg-color !default;
$input-border-color-readonly : $input-border-color !default;
$input-color-readonly        : $input-color !default;
$input-opacity-readonly        : 1 !default;

$input-bg-color-disabled     : $color-white !default; //$input-bg-color !default;
$input-border-color-disabled : $input-border-color !default;
$input-color-disabled        : $input-color !default;
$input-opacity-disabled      : .8 !default;

/*
 * Input Group
 */

$input-group-addon-bg-color     : $color-gray-light !default; //darken($input-bg-color, 10%) !default;
$input-group-addon-border-color : $color-gray-light !default; //darken($input-border-color, 10%) !default;
$input-group-addon-border-width : $input-border-width !default;
$input-group-addon-border-style : $input-border-style !default;
$input-group-addon-color        : $input-color !default; //darken($input-color, 10%) !default;
$input-group-addon-font-size    : $input-font-size !default;
$input-group-addon-font-weight  : $font-weight-normal !default;

/* -----------------------------------------------------------------------------
 * Platzhalter
 * -------------------------------------------------------------------------- */

$form-placeholder-color      : $input-color-placeholder !default;
$form-placeholder-font-style : $input-font-style !default;