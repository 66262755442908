/**
 * Buttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$btn-border-radius-base  : 0 !default;
$btn-border-radius-large : 0 !default;
$btn-border-radius-small : 0 !default;
$btn-border-style        : $comp-border-style !default;
$btn-border-width        : 2px !default; //$comp-border-width !default;
$btn-font-weight         : $font-weight-light !default;
$btn-text-transform      : uppercase !default;

/* -----------------------------------------------------------------------------
 * Standard
 * -------------------------------------------------------------------------- */

$btn-default-color        : #fff !default;
$btn-default-bg-color     : $color-gray-dark !default;
$btn-default-border-color : $color-gray-dark !default;

$btn-default-color-hover        : $btn-default-color !default;
$btn-default-bg-color-hover     : lighten($btn-default-bg-color , 8%) !default;
$btn-default-border-color-hover : lighten($btn-default-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Primaer
 * -------------------------------------------------------------------------- */

$btn-primary-color        : $color-white !default;
$btn-primary-bg-color     : $brand-primary !default;
$btn-primary-border-color : $brand-primary !default;

$btn-primary-color-hover        : lighten($btn-primary-color, 13%) !default;
$btn-primary-bg-color-hover     : lighten($btn-primary-bg-color , 8%) !default;
$btn-primary-border-color-hover : lighten($btn-primary-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Sekundaer
 * -------------------------------------------------------------------------- */

$btn-secondary-color        : $color-white !default;
$btn-secondary-bg-color     : $brand-secondary !default;
$btn-secondary-border-color : $brand-secondary !default;

$btn-secondary-color-hover        : lighten($btn-secondary-color, 13%) !default;
$btn-secondary-bg-color-hover     : lighten($btn-secondary-bg-color , 8%) !default;
$btn-secondary-border-color-hover : lighten($btn-secondary-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Tertiaer
 * -------------------------------------------------------------------------- */

$btn-tertiary-color        : $color-white !default;
$btn-tertiary-bg-color     : $brand-tertiary !default;
$btn-tertiary-border-color : $brand-tertiary !default;

$btn-tertiary-color-hover        : lighten($btn-tertiary-color, 13%) !default;
$btn-tertiary-bg-color-hover     : lighten($btn-tertiary-bg-color , 8%) !default;
$btn-tertiary-border-color-hover : lighten($btn-tertiary-border-color, 8%) !default;


/* -----------------------------------------------------------------------------
 * Success
 * -------------------------------------------------------------------------- */

$btn-success-color        : $color-white !default;
$btn-success-bg-color     : $brand-success !default;
$btn-success-border-color : $brand-success !default;

$btn-success-color-hover        : lighten($btn-success-color, 13%) !default;
$btn-success-bg-color-hover     : lighten($btn-success-bg-color , 8%) !default;
$btn-success-border-color-hover : lighten($btn-success-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */

$btn-info-color        : $color-white !default;
$btn-info-bg-color     : $brand-info !default;
$btn-info-border-color : $brand-info !default;

$btn-info-color-hover        : lighten($btn-info-color, 13%) !default;
$btn-info-bg-color-hover     : lighten($btn-info-bg-color , 8%) !default;
$btn-info-border-color-hover : lighten($btn-info-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */

$btn-warning-color        : #fff !default;
$btn-warning-bg-color     : $brand-warning !default;
$btn-warning-border-color : $brand-warning !default;

$btn-warning-color-hover        : lighten($btn-warning-color, 13%) !default;
$btn-warning-bg-color-hover     : lighten($btn-warning-bg-color , 8%) !default;
$btn-warning-border-color-hover : lighten($btn-warning-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */

$btn-danger-color        : #fff !default;
$btn-danger-bg-color     : $brand-danger !default;
$btn-danger-border-color : $brand-danger !default;

$btn-danger-color-hover        : lighten($btn-danger-color, 13%) !default;
$btn-danger-bg-color-hover     : lighten($btn-danger-bg-color , 8%) !default;
$btn-danger-border-color-hover : lighten($btn-danger-border-color, 8%) !default;

/* -----------------------------------------------------------------------------
 * Disabled
 * -------------------------------------------------------------------------- */

$btn-link-disabled-color : $color-gray-light !default;