/**
 * Styled Checkboxes/Radiobuttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */

$cbs-rbs-inline-margin : $margin-base-horizontal;

$cbs-bg-color         : $input-bg-color !default;
$cbs-border-color     : $input-border-color !default;
$cbs-border-style     : $input-border-style !default;
$cbs-border-radius    : $input-border-radius !default;
$cbs-border-width     : $input-border-width !default;
$cbs-color            : $brand-primary !default;
$cbs-font-size        : 14px;
$cbs-icon-font-family : 'FontAwesome' !default;
$cbs-icon-check       : "\f00c" !default;
$cbs-label-padding    : 10px !default;
$cbs-padding          : 20px;
$cbs-size             : 22px !default;

$rbs-bg-color      : $cbs-bg-color !default;
$rbs-border-color  : $cbs-border-color !default;
$rbs-border-style  : $cbs-border-style !default;
$rbs-border-width  : $cbs-border-width !default;
$rbs-color         : $cbs-color !default;
$rbs-label-padding : $cbs-label-padding !default;
$rbs-padding       : 20px;
$rbs-size          : $cbs-size !default;
$rbs-size-dot      : 14px !default;