/**
 * Panels.
 *
 * @author Bootstrap (modifiziert: Heiko Pfefferkorn)
 * @package namRED
 */

/**
 * Panel variant.
 * (Bootstrap adaptiert)
 *
 * @param string  $border-color         Rahmenfarbe
 * @param string  $heading-color        Ueberschrift - Textfarbe
 * @param string  $heading-bg-color     Ueberschrift - Hintergrundfarbe
 * @param string  $heading-border-color Ueberschrift - Rahmenfarbe
 */
@mixin panel-variant($border-color, $heading-color, $heading-bg-color) {
	border-color : $border-color;

	& > .panel-heading {
		color            : $heading-color;
		background-color : $heading-bg-color;
		border-color     : $border-color;

		+ .panel-collapse > .panel-body { border-top-color : $border-color; }

		.badge {
			color            : $heading-bg-color;
			background-color : $heading-color;
		}
	}
	& > .panel-footer {
		+ .panel-collapse > .panel-body { border-bottom-color : $border-color; }
	}
}