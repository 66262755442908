/**
 * Stapel
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$stack-article-delimiter-border     : 1px solid $color-gray !default;
$stack-article-image-offset         : 4% !default;
$stack-article-image-width          : 28% !default;
$stack-article-item-margin          : ($margin-base-horizontal * 2) !default;
$stack-article-title-color          : $h1-color !default;
$stack-article-title-font-size      : ($font-size-base + 2) !default; // (1) (2)
//$stack-article-title-font-weight    : $font-weight-bold !default; // (1) (2)
$stack-article-title-text-transform : none !default; // (1) (2)