/**
 * Galerien
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$gallery-ctrl-bg-color      : $brand-primary;
$gallery-ctrl-color         : $color-white;
$gallery-ctrl-font-size     : 28px;
$gallery-ctrl-size          : 50px;
$gallery-ctrl-border-radius : 0;
$gallery-ctrl-font-size     : $font-size-large;

$gallery-ctrl-prev-icon-content : "\f104";
$gallery-ctrl-next-icon-content : "\f105";

$gallery-count-bg-color    : $brand-secondary !default;
$gallery-count-color       : $brand-primary !default;
$gallery-count-font-size   : $font-size-large !default; // (1)(2)
$gallery-count-font-weight : $font-weight-bold !default; // (1)
$gallery-count-padding     : 0 $padding-base-horizontal !default; // (1)
$gallery-count-size        : $thumbnail-zoom-size !default;