/**
 * Panels
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$panel-bg-color      : transparent !default;
$panel-border-style  : solid !default;
$panel-border-width  : 2px !default;
$panel-border-radius : $comp-border-radius-base !default;

$panel-body-color   : $body-bg-color !default;
$panel-body-padding : $padding-base-vertical-large $padding-base-horizontal-large !default;

$panel-heading-bg-color  : $color-gray !default;
$panel-heading-color     : $color-gray !default;
$panel-heading-font-size : $font-size-large !default;
$panel-heading-padding   : $padding-base-vertical $padding-base-horizontal-large !default;

$panel-footer-bg-color  : $color-gray !default;
$panel-footer-color     : $body-text-color !default;
$panel-footer-padding   : $padding-base-vertical $padding-base-horizontal-large !default;

$panel-default-bg-color     : $color-gray-light !default;
$panel-default-border-color : $color-gray-light !default;
$panel-default-color        : lighten($color-gray-dark, 20%) !default;

$panel-primary-bg-color     : $brand-primary !default;
$panel-primary-border-color : $brand-primary !default;
$panel-primary-color        : $color-white !default;

$panel-secondary-bg-color     : $brand-secondary !default;
$panel-secondary-border-color : $brand-secondary !default;
$panel-secondary-color        : $color-white !default;

$panel-tertiary-bg-color     : $brand-tertiary !default;
$panel-tertiary-border-color : $brand-tertiary !default;
$panel-tertiary-color        : $color-white !default;

$panel-success-bg-color     : $state-success-bg-color !default;
$panel-success-border-color : $state-success-bg-color !default;
$panel-success-color        : $state-success-color !default;

$panel-info-bg-color     : $state-info-bg-color !default;
$panel-info-border-color : $state-info-bg-color !default;
$panel-info-color        : $state-info-color !default;

$panel-warning-bg-color     : $state-warning-bg-color !default;
$panel-warning-border-color : $state-warning-bg-color !default;
$panel-warning-color        : $state-warning-color !default;

$panel-danger-bg-color     : $state-danger-bg-color !default;
$panel-danger-border-color : $state-danger-bg-color !default;
$panel-danger-color        : $state-danger-color !default;