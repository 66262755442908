/**
 * Navigation(en)
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Hauptnavigation.
 *
 * ========================================================================== */

$nav-primary-bg-color       : transparent !default;
//$nav-primary-font-family    : $font-family-secondary !default; // (1) (2)
//$nav-primary-font-size      : 18px !default; // (1) (2)
//$nav-primary-font-weight    : $font-weight-bold !default; // (1) (2)
//$nav-primary-font-style     : $font-style-normal !default; // (1) (2)
//$nav-primary-font-variant   : normal !default; // (1) (2)
//$nav-primary-text-transform : uppercase !default; // (1) (2)

$nav-primary-item-bg-color       : transparent !default;
$nav-primary-item-color          : $brand-secondary !default;

$nav-primary-item-bg-color-hover : transparent !default;
$nav-primary-item-color-hover    : $brand-primary !default;

$nav-primary-item-bg-color-active : transparent !default;
$nav-primary-item-color-active    : $brand-primary !default;

$nav-primary-item-margin : ($margin-base-horizontal-small + $margin-base-horizontal-xsmall) !default;

/* -----------------------------------------------------------------------------
 * Untermenue.
 * -------------------------------------------------------------------------- */

$nav-primary-sub-bg-color       : $brand-primary !default;
//$nav-primary-sub-font-size      : 18px !default; // (1) (2)
$nav-primary-sub-font-weight    : $font-weight-normal !default; // (1) (2)
//$nav-primary-sub-font-style     : $font-style-normal !default; // (1) (2)
//$nav-primary-sub-font-variant   : normal !default; // (1) (2)
//$nav-primary-sub-text-transform : none !default; // (1) (2)

$nav-primary-sub-item-bg-color       : darken($brand-primary, 6%) !default;
$nav-primary-sub-item-color          : rgba($color-white, 0.7) !default;

$nav-primary-sub-item-bg-color-hover : $nav-primary-sub-item-bg-color !default;
$nav-primary-sub-item-color-hover    : $color-white !default;

$nav-primary-sub-item-bg-color-active : $nav-primary-sub-item-bg-color !default;
$nav-primary-sub-item-color-active    : $brand-secondary !default;

/* =============================================================================
 *
 * Servicenavigation.
 *
 * ========================================================================== */

//$nav-service-font-size       : 24px !default; // (1) (2)
//$nav-service-font-weight     : $font-weight-bold; // (1) (2)
//$nav-service-text-transform  : uppercase !default; // (1) (2)

$nav-service-item-bg-color        : transparent !default;
$nav-service-item-color           : $nav-primary-item-color !default;
$nav-service-item-bg-color-hover  : transparent !default;
$nav-service-item-color-hover     : $brand-primary !default;
$nav-service-item-bg-color-active : transparent !default;
$nav-service-item-color-active    : $brand-primary !default;
$nav-service-item-bg-color-focus  : transparent !default;
$nav-service-item-color-focus     : $brand-primary !default;
$nav-service-item-margin          : 15px !default;

/* =============================================================================
 *
 * Metanavigation.
 *
 * ========================================================================== */

$nav-meta-font-family     : $font-family-base !default; // (1) (2)
$nav-meta-font-size       : $font-size-small !default; // (1) (2)
$nav-meta-font-weight     : $font-weight-light; // (1) (2)
$nav-meta-text-transform  : none !default; // (1) (2)

$nav-meta-item-bg-color        : transparent !default;
$nav-meta-item-color           : $color-gray-dark !default;
$nav-meta-item-bg-color-hover  : transparent !default;
$nav-meta-item-color-hover     : $brand-primary !default;
$nav-meta-item-bg-color-active : transparent !default;
$nav-meta-item-color-active    : $color-white !default;
$nav-meta-item-bg-color-focus  : transparent !default;
$nav-meta-item-color-focus     : $color-white !default;
$nav-meta-item-margin          : $margin-base-horizontal-small !default;