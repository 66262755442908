/**
 * Tabellen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$table-nested-bg-color : $color-white;

$table-default-color      : $body-text-color !default;
$table-default-margin     : $margin-base-vertical 0 !default;
$table-default-padding    : ($comp-padding-vertical-base + 2) $comp-padding-horizontal-base !default;
$table-default-text-align : left;
$table-default-width      : 100%;

$table-default-caption-color      : $color-gray-light;
$table-default-caption-padding    : $comp-padding-vertical-base $comp-padding-horizontal-base !default;
$table-default-caption-text-align : left;

$table-default-header-color          : $brand-primary !default;
//$table-default-header-font-family    : $font-family-base; // (1)(2)
$table-default-header-font-size      : $font-size-base !default; // (1)(2)
$table-default-header-font-weight    : $font-weight-normal !default;
$table-default-header-text-align     : left;
$table-default-header-text-transform : uppercase;

$table-default-row-header-color       : $table-default-header-color !default;
$table-default-row-header-font-weight : $table-default-header-font-weight !default;
$table-default-row-header-text-align  : left;

/* -----------------------------------------------------------------------------
 * @.table
 * -------------------------------------------------------------------------- */

$table-header-border : 1px solid $brand-primary !default;
$table-row-border    : 1px solid $color-gray !default;

/* -----------------------------------------------------------------------------
 * @.table-condensed
 * -------------------------------------------------------------------------- */

$table-condensed-cell-padding-horizontal : 5px !default;
$table-condensed-cell-padding-vertical   : 3px !default;

/* -----------------------------------------------------------------------------
 * @.table-bordered
 * -------------------------------------------------------------------------- */

$table-bordered-border: 1px solid $color-gray !default;

/* -----------------------------------------------------------------------------
 * @.table-striped
 * -------------------------------------------------------------------------- */

$table-zebra-bg-color-even : $color-even;
$table-zebra-bg-color-odd  : $color-odd;

/* -----------------------------------------------------------------------------
 * @Hover
 * -------------------------------------------------------------------------- */

$table-hover-bg-color-hover: #fff;

/* -----------------------------------------------------------------------------
 * @Hintergrundfarben
 * -------------------------------------------------------------------------- */

$table-bg-color-active  : $color-odd;
$table-bg-color-success : lighten($brand-success, 25%) !default;
$table-bg-color-info    : lighten($brand-info, 25%) !default;
$table-bg-color-warning : lighten($brand-warning, 25%) !default;
$table-bg-color-danger  : lighten($brand-danger, 25%) !default;