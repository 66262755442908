/**
 * Be- und Umrechnungen (PX, EM, REM, ...).
 *
 * @author Stephen Rushing, eSiteful
 * @url http://github.com/stephenr85/sass-unity
 * @package namRED
 */

$baseline    : $font-size-base !default;
$em-baseline : $baseline !default;
$px-per-inch : 96px !default;
$pt-per-inch : 72pt !default;

/**
 * If a number has a .00001 decimal, it's probably a rounding issue and needs
 * to be dropped.
 * Example:
 *     sub-px(13.00005px) = 13px
 *
 * @function sub-px
 * @param string/number $dim
 *
 * return number
 */
@function sub-px($dim) {
	$dim   : $dim * 1;
	$-diff : $dim - floor($dim);

	@if ($-diff >= .00001 and $-diff > 0) {
		@return floor($dim);
	}

	@return $dim;
}

/**
 * Removes the unit from a number.
 * Example:
 *     strip-unit(16px) = 16
 *
 * @function strip-unit
 * @param string/number $num
 *
 * return number
 */
@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

/**
 * Find the first unit in a list of numbers.
 *
 * Examples:
 *     first-unit(0 12% 10% 1px) = px
 *
 * @function first-unit
 * @param number/list $dims
 *
 * return string
 */
@function first-unit($dims...) {
	$unit : null;

	@each $dim in $dims {
		@if ($unit == null) {
			@if (type-of($dim) == "number" and unitless($dim) == false) {
				@return unit($dim);
			} @else if (type-of($dim) == "list" or type-of($dim) == "arglist") {
				@each $val in $dim {
					//repeat logic
					@if ($unit == null) {
						@if (type-of($val) == "number" and unitless($val) == false and unit($val) != "%") {
							@return unit($val);
						} @else if (type-of($val) == "list" or type-of($val) == "arglist") {
							@return first-unit($val);
						}
					}
				}
			}
		}
	}

	@return $unit;
}

/**
 * Convert any number to a px, or ensure a px value.
 * Percentages are retained. Use percent() to calculate static values.
 *
 * Examples:
 *     px(1rem) = 16px
 *     px(1 4rem 2em 10%) = 1px 64px 26px 10%
 *
 * @function px
 * @param number/list $dim
 *
 * return number A px value
 */
@function px($dim) {
	$-is-list : type-of($dim) == "arglist" or type-of($dim) == "list";

	@if ($-is-list and length($dim) > 1) {
		$pxs : ();

		@each $value in $dim {
			$pxs : append($pxs, px($value));
		}

		@if (length($pxs) == 1) {
			@return nth($pxs, 1);
		} @else {
			@return $pxs;
		}
	} @else if ($-is-list) {
		$dim : nth($dim, 1);
	}

	@if ($dim == "auto") {
		@return "auto";
	}

	$dim : $dim * 1;

	@if (type-of($dim) == "string" and $dim == "auto") {
		@return $dim;
	} @else if (type-of($dim) != "number") {
		@warn $dim + " cannot be converted to px.";
		@return null;
	} @else if (unit($dim) == "rem") {
		//Convert rem to px
		@return sub-px(strip-unit($dim) * px($baseline));
	} @else if (unit($dim) == "em") {
		//Convert em to px
		@return sub-px(strip-unit($dim) * px($em-baseline));
	} @else if (unit($dim) == "pt") {
		//Convert pt to px
		@return sub-px(strip-unit($dim) / px($px-per-inch));
	} @else if (unit($dim) == "%") {
		//Retain percentages
		@return sub-px($dim);
	} @else {
		//Ensure px unit
		@return sub-px(strip-unit($dim)) + 0px;
	}
}

/**
 * Convert a number to an rem, or ensure an rem value.
 * Percentages are retained. Use percent() to calculate static values.
 *
 * @function rem
 * @param number $dim
 *
 * return number A rem value
 */
@function rem($dim) {
	$-is-list : type-of($dim) == "arglist" or type-of($dim) == "list";

	@if ($-is-list and length($dim) > 1) {
		$rems : ();

		@each $value in $dim {
			$rems : append($rems, rem($value), space);
		}

		@if (length($rems) == 1) {
			@return nth($rems, 1);
		} @else {
			@return $rems;
		}
	} @else if ($dim == "auto") {
		@return "auto";
	} @else if ($-is-list) {
		$dim : nth($dim, 1);
	}

	@if ($dim == "auto") {
		@return "auto";
	}

	$dim : $dim * 1;

	@if (type-of($dim) != "number") {
		@warn $dim + " cannot be converted to rem.";
		@return null;
	} @else if (unit($dim) == "rem" or unitless($dim)) {
		//Ensure rem unit
		@return strip-unit($dim) + 0rem;
	} @else if (unit($dim) == "%") {
		//Retain percentages
		@return $dim;
	} @else {
		//Convert to px, then to rem
		$dim : px($dim);

		@return strip-unit($dim) / strip-unit($baseline) + 0rem;
	}
}

/**
 * Sometimes em values are still viable, obviously, but you have to know the
 * context for calculations. The baseline is stored, so each subsequent call
 * will use the previous $baseline parameter, if none is passed.
 *
 * @function em
 * @param number $dim
 * @param number $baseline [global $em-baseline] However many px = 1em,
 *                                               or the relative em of the
 *                                               current context.
 * @param boolean $adjust  [false]               When true, adjusts the global
 *                                               $em-baseline to whatever the
 *                                               result is. This is useful if
 *                                               you want to set things
 *                                               font-size: em(12px).
 *
 * return number A em value
 */
@function em($dim, $baseline : $em-baseline, $adjust : false) {
	$-is-list : type-of($dim) == "arglist" or type-of($dim) == "list";

	@if ($-is-list and length($dim) > 1) {
		$ems : ();

		@each $value in $dim {
			$ems : append($ems, em($value), space);
		}

		@if (length($ems) == 1) {
			@return nth($ems, 1);
		} @else {
			@return $ems;
		}
	} @else if ($-is-list) {
		$dim : nth($dim, 1);
	}

	@if ($dim == "auto") {
		@return "auto";
	}

	$dim : $dim * 1;

	@if (type-of($dim) != "number") {
		@warn $dim + " cannot be converted to em.";
		@return null;
	} @else if (unit($dim) == "em" or unitless($dim)) {
		//Ensure em unit
		@return strip-unit($dim) + 0em;
	} @else {
		//Make sure $dim is a px, then convert
		$dim : px($dim);
		$-em : strip-unit($dim / px($baseline)) + 0em;

		@if ($adjust) {
			$em-baseline : $dim; //Update global $em-baseline
		}

		@return $-em;
	}
}

/**
 * Convert arbitrary units to or from percentages.
 * This works a little differently than the other unit functions, because it
 * does not retain units of its own type. In other words, a % value will be
 * converted, rather than returned entact.
 *
 * Examples:
 *     percent(24px, 240px) = 10%
 *     percent(1.5rem, 240px) = 10%
 *     percent(10%, 240px) = 24px
 *     percent(10%, rem(240px)) = 1.5rem
 *
 * @function percent
 * @param number $dim     If a %
 * @param number $context The total width for the calculation of the percentages.
 *
 * @return number If $dim is a % value, returns the percent calculation in the
 *                same unit as $context.
 *                If $dim is another unit, a % will be returned.
 */
@function percent($dim, $context) {
	$-is-list : type-of($dim) == "arglist" or type-of($dim) == "list";

	@if ($-is-list and length($dim) > 1) {
		$percents : ();

		@each $value in $dim {
			$percents : append($percents, percent($value, $context), space);
		}

		@if (length($percents) == 1) {
			@return nth($percents, 1);
		} @else {
			@return $percents;
		}
	} @else if ($dim == "auto") {
		@return "auto";
	}

	$dim : $dim * 1;

	@if (type-of($dim) != "number") {
		@warn $dim + " cannot be converted to percent.";
		@return null;
	} @else if (unit($dim) == "%" or unitless($dim)) {
		//Calculate percentage
		@return strip-unit($dim / 100) * $context;
	} @else {
		//Convert to px then percentage
		@return strip-unit(px($dim) / px($context)) * 100%;
	}
}

/**
 * Visually relative to the pixels per inch of a device, but provides a
 * consistent mathmatical formula for converting units to pts for print.
 * Totally untested with actual print at this point.
 *
 * Examples:
 *     pt(16px) = 12pt
 *
 * @function pt
 * @param number $dim
 * @param number $px-per-inch Defaults to global $px-per-inch (96).
 * @param number $pt-per-inch Defaults to global $pt-per-inch (72).
 *
 * @return number A pt value.
 */
@function pt($dim, $px-per-inch : $px-per-inch, $pt-per-inch : $pt-per-inch) {
	$-is-list : type-of($dim) == "arglist" or type-of($dim) == "list";

	@if ($-is-list and length($dim) > 1) {
		$pts : ();

		@each $value in $dim {
			$pts : append($pts, pts($value), space);
		}

		@if (length($pts) == 1) {
			@return nth($pts, 1);
		} @else {
			@return $pts;
		}
	} @else if ($dim == "auto") {
		@return "auto";
	}

	$dim : $dim * 1;

	@if (type-of($dim) != "number") {
		@warn $dim + " cannot be converted to px.";
		@return null;
	} @else if (unit($dim) == "pt" or unitless($dim)) {
		@return strip-unit($dim) + 0pt;
	} @else {
		//Convert to px, then pt
		$dim : strip-unit(px($dim));

		@return $dim * strip-unit($pt-per-inch) / strip-unit($px-per-inch) + 0pt;
	}
}

/**
 * Visually relative to the pixels per inch of a device, but provides a
 * consistent mathmatical formula for converting units to pts for print.
 * Totally untested with actual print at this point.
 *
 * Examples:
 *     cast-unit(px, 3em, 4em, (5))
 *
 * @function cast-unit
 * @param string $unit [em,pt,px,rem]
 * @param number $dims
 */
@function cast-unit($unit, $dims...) {
	@if ($unit == "em") {
		@return em(($dims));
	} @else if ($unit == "rem") {
		@return rem(($dims));
	} @else if ($unit == "pt") {
		@return pt(($dims));
	} @else if ($unit == "px") {
		@return px(($dims));
	} @else {
		@warn "Cannot cast \"#{$unit}\" unit for "+ $dims;
	}

	@return null;
}

/**
 * Convert all numbers to the same unit type. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     unity(12px, 1em) = #{unity(12px, 1em)}
 *     unity(1em, 14px, 1.2rem, (16, 8%)) = 1em .25em 1.45em 16em 8%
 *
 * @function unity
 * @param number $dims
 *
 * @return number
 */
@function unity($dims...) {
	$unified : ();
	$unit    : first-unit($dims);

	@each $dim in $dims {
		@if (type-of($dim) != "list" and type-of($dim) != "arglist") {
			$dim : ($dim);
		}

		@each $val in $dim {
			$val     : cast-unit($unit, $val);
			$unified : append($unified, $val);
		}
	}

	@return $unified;
}

/**
 * Add numbers, normalizing them first. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     plus(1rem, 4px, 7em, 5)
 *
 * @function plus
 * @param number $dims
 *
 * @return number
 */
@function plus($dims...) {
	$dims  : unity($dims);
	$total : 0;

	@each $dim in $dims {
		@if (unit($dim) != "%" and $dim != "auto") {
			$total : $total + $dim;
		}
	}

	@return $total;
}

/**
 * Subtract numbers, normalizing them first. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     minus(20rem, 4px, 7em, 5)
 *
 * @function minus
 * @param number $dims
 *
 * @return number
 */
@function minus($dims...) {
	$dims  : unity($dims);
	$total : 0;

	@each $dim in $dims {
		@if (unit($dim) != "%" and $dim != "auto") {
			$total : $total - $dim;
		}
	}

	@return $total;
}

/**
 * Use rem units with px fallback.
 * Specify a px, em, or rem value with each property. Unitless values will be
 * considered an rem.
 *
 * Examples:
 *     rem(font-size 12px, line-height 1.4em)
 *
 * @mixin rem
 * @param string $properties
 *
 * @return number
 */
@mixin rem($properties...) {
	@each $property in $properties {
		$prop-name : nth($property, 1);
		$pxs       : ();
		$rems      : ();

		@for $i from 2 through length($property) {
			$value : nth($property, $i);

			@if ($value == "auto") {
				$pxs  : append($pxs, $value, space);
				$rems : append($rems, $value, space);
			} @else if (unit($value) == "px") {
				$pxs  : append($pxs, $value, space);
				$rems : append($rems, rem($value), space);
			} @else {
				$pxs  : append($pxs, strip-unit($value) * $baseline, space);
				$rems : append($rems, rem($value), space);
			}
		}

		//Output
		#{$prop-name}: $pxs; /* px fallback for rem */
		#{$prop-name}: $rems;
	}
}