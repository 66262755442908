/**
 * Seitenblaettern (Pagination)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$pagination-border-radius : 0 !default; //$comp-border-radius-base
$pagination-color         : $color-gray-darker !default;
$pagination-margin        : 0 !default;

$pagination-item-bg-color     : $color-gray-lighter !default;
$pagination-item-border-color : $color-gray-lighter !default;
$pagination-item-border-width : 1px !default;
$pagination-item-color        : $pagination-color !default;
$pagination-item-margin       : 0 10px 0 0 !default;
$pagination-item-padding      : 6px 12px !default;

$pagination-item-bg-color-hover     : $color-gray-lighter;
$pagination-item-border-color-hover : $brand-secondary !default;
$pagination-item-color-hover        : $brand-secondary !default;

$pagination-item-bg-color-active     : $brand-secondary;
$pagination-item-border-color-active : $brand-secondary !default;
$pagination-item-color-active        : $color-white !default;

//$pagination-item-bg-color-focus     : $pagination-item-bg-color-hover;
//$pagination-item-border-color-focus : $pagination-item-border-color-hover !default;
//$pagination-item-color-focus        : $pagination-item-color-hover !default;

$pagination-item-bg-color-disabled     : $color-gray-lighter !default;
$pagination-item-border-color-disabled : $color-gray-lighter !default;
$pagination-item-color-disabled        : $color-gray !default;
$pagination-item-opacity-disabled      : 0.4 !default; // (1)