/**
 *
 * This file is part of namRED Content Management Framework.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author heiko
 * @package namRED
 */

@mixin tab-focus() {
	outline        : thin dotted; // Default
	outline        : 5px auto -webkit-focus-ring-color; // WebKit
	outline-offset : -2px;
}