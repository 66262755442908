/**
 * Tabs
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$tabs-container-border  : 2px solid $color-gray;
$tabs-container-padding : $padding-base-vertical-small $padding-base-horizontal-small;

$tabs-border-radius : $comp-border-radius-base;

$tabs-toggle-color    : $color-gray-darker;
$tabs-toggle-bg-color : transparent;

$tabs-toggle-color-hover    : $tabs-toggle-color;
$tabs-toggle-bg-color-hover : $color-gray-light;

$tabs-toggle-color-active    : $tabs-toggle-color-hover;
$tabs-toggle-bg-color-active : $tabs-toggle-bg-color-hover;