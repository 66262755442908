/**
 * Backgrounds.
 *
 * @author Bootstrap
 * @package namRED
 */

/**
 * Textfarbe plus :hover.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
@mixin text-emphasis-variant($parent, $color) {
	#{$parent} { color : $color; }

	a#{$parent}:hover { color : darken($color, 10%); }
}

/**
 * Textfarben.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
@mixin text-color-variant($parent, $color) {
	#{$parent} { color : $color; }
}