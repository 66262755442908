/**
 * Artikels
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$article-header-margin       : $margin-base-vertical-large !default; // top right bottom left
$article-body-section-margin : $margin-base-vertical-large !default;
$article-footer-margin       : $article-header-margin !default; // top right bottom left