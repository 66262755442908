/**
 * Import von Vorlagen, wiederverwendbaren Mustern.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* -----------------------------------------------------------------------------
 * Utilities
 * -------------------------------------------------------------------------- */

//@import 'mixins/_opacity';
@import 'mixins/_overflow';
@import 'mixins/_respond-to';
@import "mixins/_text";
@import 'mixins/_tab-focus';

/* -----------------------------------------------------------------------------
 * Komponenten
 * -------------------------------------------------------------------------- */

@import 'mixins/_alerts';
@import 'mixins/_buttons';
@import "mixins/_panels";
@import 'mixins/_headings';
@import 'mixins/_forms';
@import 'mixins/_tables';
@import 'mixins/_pagination';

/* -----------------------------------------------------------------------------
 * Skins
 * -------------------------------------------------------------------------- */

@import "mixins/background-variant";
@import 'mixins/_gradients';

/* -----------------------------------------------------------------------------
 * Layout
 * -------------------------------------------------------------------------- */

@import 'mixins/_grid-variant';
@import 'mixins/_box-model';
@import 'mixins/_clearfix';
@import 'mixins/_center-block';
@import 'mixins/_lists';