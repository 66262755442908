/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/**
 * Horizontaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 */
@mixin nr-gradient-horizontal($start-color: #fff, $end-color: #fff, $start-percent: 0%, $end-percent: 100%) {
	background        : $start-color; /* Old browsers */
	background        : linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); /* W3C */
	background        : -moz-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); /* FF3.6+ */
	background        : -ms-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); /* IE10+ */
	background        : -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); /* Opera 11.10+ */
	background        : -webkit-gradient(linear, left top, right top, color-stop($start-percent, $start-color), color-stop($end-percent, $end-color)); /* Chrome,Safari4+ */
	background        : -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); /* Chrome10+,Safari5.1+ */
	background-repeat : repeat-x;

	/* (invalid): Funktioniert aber in IE 6-8 */
	.lte8 & { filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{ie-hex-str($start-color)}, endColorstr=#{ie-hex-str($end-color)}); }

	/* (valid): Funktioniert in IE 8-9 */
	.lte9  & { -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#{ie-hex-str($start-color)}, endColorstr=#{ie-hex-str($end-color)})"; }
}

/**
 * Vertikaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 */
@mixin nr-gradient-vertical($start-color: #fff, $end-color: #fff, $start-percent: 0%, $end-percent: 100%) {
	background        : $start-color; /* Old browsers */
	background        : linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); /* W3C */
	background        : -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); /* FF3.6+ */
	background        : -ms-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); /* IE10+ */
	background        : -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); /* Opera 11.10+ */
	background        : -webkit-gradient(linear, left top, left bottom, color-stop($start-percent, $start-color), color-stop($end-percent, $end-color)); /* Chrome,Safari4+ */
	background        : -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); /* Chrome10+,Safari5.1+ */
	background-repeat : repeat-x;
	/* (invalid): Funktioniert aber in IE 6-8 */
	.lte8 & { filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{ie-hex-str($start-color)}, endColorstr=#{ie-hex-str($end-color)}); }

	/* (valid): Funktioniert in IE 8-9 */
	.lte9  & { -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#{ie-hex-str($start-color)}, endColorstr=#{ie-hex-str($end-color)})"; }
}

/**
 * Diagonaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 * @param string $deg           Gradanzahl
 */
@mixin nr-gradient-diagonal($start-color: #fff, $end-color: #fff, $start-percent: 0%, $end-percent: 100%, $deg: 45deg) {
	background        : $start-color; /* Old browsers */
	background        : linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); /* W3C */
	background        : -moz-linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); /* FF3.6+ */
	background        : -ms-linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); /* IE10+ */
	background        : -o-linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); /* Opera 11.10+ */
	background        : -webkit-gradient(linear, left bottom, right top, color-stop($start-percent, $start-color), color-stop($end-percent, $end-color)); /* Chrome,Safari4+ */
	background        : -webkit-linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); /* Chrome10+,Safari5.1+ */
	background-repeat : repeat-x;

	/* (invalid): Funktioniert aber in IE 6-8 */
	.lte8 & { filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{ie-hex-str($start-color)}, endColorstr=#{ie-hex-str($end-color)}); }

	/* (valid): Funktioniert in IE 8-9 */
	.lte9  & { -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#{ie-hex-str($start-color)}, endColorstr=#{ie-hex-str($end-color)})"; }
}