/**
 * Dropdowns
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$dropdown-menu-bg-color       : $color-white;
$dropdown-menu-border-color   : $input-bg-color-focus;
$dropdown-menu-box-shadow     : none;
$dropdown-menu-border-width   : 1px;
$dropdown-menu-border-style   : solid;
$dropdown-menu-border-color   : $brand-tertiary;
$dropdown-menu-border-radius  : $comp-border-radius-base;
$dropdown-menu-color          : $input-color;
$dropdown-menu-font-size      : $font-size-base;
$dropdown-menu-font-style     : $font-style-normal;
$dropdown-menu-text-transform : none;

$dropdown-divider-margin : 0;

$dropdown-menuitem-bg-color-hover : $color-gray-lighter;
$dropdown-menuitem-color          : $input-color;
$dropdown-menuitem-font-weight    : $font-weight-light;
$dropdown-menuitem-padding        : $padding-base-vertical-small $padding-base-horizontal;
$dropdown-menuitem-color-hover    : $input-color;

$dropdown-header-bg-color      : $color-gray-lighter;
$dropdown-header-color         : $brand-tertiary;
$dropdown-header-font-size     : $font-size-xsmall;
$dropdown-header-font-weight   : $font-weight-normal;
$dropdown-header-margin        : 0;
$dropdown-header-padding       : $padding-base-vertical-xsmall 0;
$dropdown-header-text-align    : center;
