/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/**
 * Grid-Spalten generieren.
 *
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
@mixin grid-gutter-variant-make-columns($gutter-width, $grid-columns) {
	$i    : 1;
	$list : ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";

	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
	}

	> #{$list} {
		padding-left  : ceil(($gutter-width / 2));
		padding-right : floor(($gutter-width / 2));
	}
}

/**
 * Grid-Variante generieren.
 *
 * @param string $ident        Teil des neuen Selectors der an '.row-' angefuegt
 *                             wird. Bsp. (md): .row-md
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
@mixin grid-gutter-variant($ident, $gutter-width: 0, $grid-columns: 12) {
	.row-#{$ident} {
		margin-left  : ceil(($gutter-width / -2));
		margin-right : floor(($gutter-width / -2));
		@include grid-gutter-variant-make-columns($gutter-width, $grid-columns);
	}
}