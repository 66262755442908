/**
 * Schrift
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Body
 *
 * ========================================================================== */

$body-text-color   : $brand-secondary;
$body-font-family  : $font-family-base;
$body-line-height  : $line-height-base;
$body-font-size    : $font-size-base;
$body-font-style   : normal;
$body-font-variant : normal;
$body-font-weight  : $font-weight-light;
$body-text-align   : left;

/* =============================================================================
 *
 * Ueberschriften
 *
 * ========================================================================== */

$h1-color          : $brand-secondary;
$h1-font-size      : 36px;
$h1-font-style     : normal;
$h1-font-variant   : normal;
$h1-font-weight    : $font-weight-light;
$h1-line-height    : 20px;
$h1-margin         : ($margin-base-vertical-large + $margin-base-vertical) 0; // top right bottom left
$h1-small-color    : $h1-color;
$h1-small-color    : $h1-color;
$h1-text-transform : uppercase;

$h2-color          : $brand-secondary;
$h2-font-size      : 20px;
$h2-font-style     : normal;
$h2-font-variant   : normal;
$h2-font-weight    : $font-weight-light;
$h2-line-height    : 20px;
$h2-margin         : $margin-base-vertical-large 0; // top right bottom left
$h2-small-color    : $h2-color;
$h2-text-transform : uppercase;

$h3-color          : $brand-primary;
$h3-font-size      : 18px;
$h3-font-style     : normal;
$h3-font-variant   : normal;
$h3-font-weight    : $font-weight-bold;
$h3-line-height    : 20px;
$h3-margin         : $margin-base-vertical 0; // top right bottom left
$h3-small-color    : $h3-color;
$h3-text-transform : none;

$h4-color          : $brand-primary;
$h4-font-size      : 16px;
$h4-font-style     : normal;
$h4-font-variant   : normal;
$h4-font-weight    : $font-weight-bold;
$h4-line-height    : 20px;
$h4-margin         : $margin-base-vertical 0; // top right bottom left
$h4-small-color    : $h4-color;
$h4-text-transform : none;

/* =============================================================================
 *
 *  Sonstiges
 *
 * ========================================================================== */

$text-muted-color : $color-gray-light !default;

$mark-bg-color : $state-warning-bg-color !default;
$mark-padding  : .2em !default;

$blockquote-bg-color     : $color-gray-lighter !default;
$blockquote-border-color : transparent !default;
$blockquote-border-style : solid !default;
$blockquote-border-width : 0 !default;
$blockquote-color        : $color-gray-dark !default;
//$blockquote-font-family  : $font-family-base !default; // (1)
$blockquote-font-size    : 30px !default; // (1)
$blockquote-font-style   : $font-style-italic !default;
$blockquote-font-weight  : $font-weight-light !default;
$blockquote-margin       : ($margin-base-vertical-large * 2) 0 !default; // top right bottom left (1)
$blockquote-padding      : 20px 20px 20px 80px !default;

$abbr-border-bottom : 1px dashed #e2e200 !default;

/* -----------------------------------------------------------------------------
 * Code
 * -------------------------------------------------------------------------- */

$code-bg-color      : rgba($color-black, 0.12);
$code-border-radius : $border-radius-base;
$code-color         : $color-white;

$kbd-bg-color   : $color-gray-darker;
$kbd-color      : #fff;
$kbd-box-shadow : none;

$pre-color         : $color-gray-light;
$pre-bg-color      : #fff;
$pre-border        : 1px solid $color-gray-lighter;
$pre-border-radius : $border-radius-base;